export const LangComJa = {
  pageBack: '戻る',
  pageNext: '進む',
  close: '閉じる',
  send: '送信',
  langtoggle: 'English',
  seo: {
    title: 'Hare-Systems（ハレシス）',
    description: `Hare-Systemsはアプリケーション開発から開発支援、業務効率の改善、UX向上支援や業務改善など、テクノロジーでビジネスを支える活動を行っています。`,
    keywords: `Hare-Systems,ハレシステムズ,アプリケーション,開発,システム,エンジニア,harenokuni,晴れの国,岡山`,
  },
};
