import type { LangComJa } from './lang-com-ja';
export const LangComEn: typeof LangComJa = {
  pageBack: 'Back',
  pageNext: 'Next',
  close: 'Close',
  send: 'Send',
  langtoggle: '日本語',
  seo: {
    title: 'Hare-Systems.net',
    description: `Hare-Systems supports businesses with technology, from application development to development support, business efficiency improvement, UX improvement support and business improvement.`,
    keywords: `Hare-Systems,Application,Application Development,systems engineer,harenokuni,the Land of the Rising Sun,Okayama`,
  },
};
