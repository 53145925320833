import { defineNuxtPlugin } from '#app'
import { createVuetify } from 'vuetify'

const isDev = process.env.NODE_ENV === "development"
const options = JSON.parse('{"ssr":true,"treeshaking":true,"theme":{"defaultTheme":"myCustomLightTheme","themes":{"myCustomLightTheme":{"dark":false,"colors":{"back":"#fff","theme1":"#FF9100","theme2":"#FF9C19","accent1":"#0073B3","accent2":"#00A4FF","info":"#2196F3","success":"#4CAF50","warning":"#FB8C00","error":"#B00020","gray":"#666666","white":"#FFF","black":"#000"}}}}}')

''

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  if (!process.server && isDev) {
    // eslint-disable-next-line no-console
    console.log('💚 Initialized Vuetify 3', vuetify)
  }

  return {
    provide: {
      vuetify
    }
  }
})
