import plugin_vue3_A0OWXRrUgq from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_plugin_b10ed17c_kmQvpemgdR from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/.nuxt/templates.plugin.b10ed17c.mts";
import chunk_reload_client_UciE0i6zes from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  templates_plugin_b10ed17c_kmQvpemgdR,
  chunk_reload_client_UciE0i6zes
]