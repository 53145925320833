<script setup lang="ts">
// [dayjs]
import * as dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ja from 'dayjs/locale/ja';
// [stores]
import { useAppStore } from '@/stores/app-store';
import { useLangStore } from '@/stores/lang-store';
// [lib]
import { Sleep } from '@/lib/lib-com';
// ---------------------------------------------------
// [dayjs]
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale(ja);
// ---------------------------------------------------
// [ Nuxt ]
const route = useRoute();
const config = useRuntimeConfig();
// [ stores ]
const appStore = useAppStore();
// [ store - lang ]
const langStore = useLangStore();
langStore.init(/^\/en/.test(route.path) ? 'en' : 'ja');
const t = langStore.t;
// ---------------------------------------------------
useHead({
  htmlAttrs: { lang: () => langStore.state.lang, prefix: 'og: http://ogp.me/ns#' },
  meta: [
    { 'http-equiv': 'refresh', content: '' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0,maximum-scale=1.0' },
    {
      name: 'keywords',
      content: () => t('com.seo.keywords'),
    },
    { name: 'format-detection', content: 'telephone=no' },
    { name: 'theme-color', content: '#FF9100' },
  ],
  link: [
    { rel: 'shortcut icon', href: '/assets/icon/favicon.ico', type: 'image/x-icon' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon.png' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-57x57.png', sizes: '57x57' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-72x72.png', sizes: '72x72' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-76x76.png', sizes: '76x76' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-114x114.png', sizes: '114x114' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-120x120.png', sizes: '120x120' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-144x144.png', sizes: '144x144' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-152x152.png', sizes: '152x152' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-180x180.png', sizes: '180x180' },
  ],
  script: [
    // アクセス解析研究所
    { src: '//accaii.com/haresystems/script.js' },
  ],
  noscript: [{ children: 'JavaScript is required' }],
  bodyAttrs: {
    class: () => (/^\/en/.test(route.path) ? 'lang-en' : 'lang-ja'),
  },
});
// プロパティ詳細は以下URL参照すること
// https://github.com/harlan-zw/zhead/blob/main/src/metaFlat.ts
useSeoMeta({
  title: () => t('com.seo.title'),
  ogTitle: () => t('com.seo.title'),
  description: () => t('com.seo.description'),
  ogDescription: () => t('com.seo.description'),
  ogImage: config.public.url + 'assets/img/OGP.png',
  twitterCard: 'summary_large_image',
});
// ---------------------------------------------------
const isHide = ref(false);
watch(
  () => appStore.state.isReady,
  async () => {
    if (appStore.state.isReady) {
      await Sleep(1000);
      isHide.value = true;
    }
  }
);
// ---------------------------------------------------
const fixed = computed(() => ({ height: appStore.windowFixedH }));
// ---------------------------------------------------
onMounted(() => {
  console.log(`${config.public.p.name} [${config.public.p.version}]:${config.public.env}`);
  appStore.init();
});
// ---------------------------------------------------
</script>
<template>
  <NuxtLayout>
    <!-- アクセス解析研究所 -->
    <ClientOnly>
      <noscript><img src="//accaii.com/haresystems/script?guid=on" /></noscript>
    </ClientOnly>
    <Teleport to="body">
      <div v-if="!isHide" class="base-cover" :class="{ isReady: appStore.state.isReady }" :style="fixed">
        <span class="loader"></span>
      </div>
    </Teleport>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.base-cover {
  position: fixed;
  inset: 0;
  background-color: #ff9100;
  z-index: 9999;
  transition: opacity 400ms;
  will-change: opacity;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  &.isReady {
    opacity: 0;
    pointer-events: none;
  }
}

.loader {
  max-width: 300px;
  width: calc(100% - 20px);
  height: 5px;
  display: inline-block;
  position: relative;
  background: rgba(130, 89, 57, 0.598);
  overflow: hidden;
  &::after {
    content: '';
    width: 40%;
    height: 20px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: base-loader 800ms linear infinite;
  }
}
.lang-toggle {
  position: fixed;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    inset: 6px 6px auto auto;
  }

  @media screen and (min-width: #{ 600 }px) {
    inset: 10px 10px auto auto;
  }
  z-index: 1;
  > button {
    width: 100px;
  }
}
</style>
