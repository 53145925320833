import { defineStore } from 'pinia';
import { useEventListener } from '@vueuse/core';
import { Sleep } from '@/lib/lib-com';

interface State {
  state: {
    isInit: boolean;
    isReady: boolean;
    window: {
      h: number;
      w: number;
    };
    contactForm: {
      name: string;
      email: string;
      message: string;
      checked: boolean;
      checkTs: string;
      isLoadingSend: boolean;
    };
  };
}
// ローディング終了までの時間
const isReadyDeray = 300;
export const useAppStore = defineStore({
  id: 'AppStore',
  state: (): State => {
    return {
      state: {
        isInit: false,
        isReady: false,
        window: {
          h: 0,
          w: 0,
        },
        contactForm: {
          // --------------
          name: '',
          email: '',
          message: '',
          // --------------
          // Sample
          // name: '岡山 太郎',
          // email: 'ryo@example.com',
          // message: 'ここに質問が入力されています',
          // --------------
          checked: false,
          checkTs: '',
          isLoadingSend: false,
        },
      },
    };
  },
  getters: {
    windowFixedW({ state }) {
      if (state.window.w !== 0) return `${state.window.w}px`;
      return `100vw`;
    },
    windowFixedH({ state }) {
      if (state.window.h !== 0) return `${state.window.h}px`;
      return `100vh`;
    },
  },
  actions: {
    async init() {
      await Sleep(0);
      const state = this.state;
      if (state.isInit) return;
      // console.log('useAppStore init');
      useEventListener(window, 'resize', () => {
        state.window.h = window.innerHeight;
        state.window.w = window.innerWidth;
      });
      state.window.h = window.innerHeight;
      state.window.w = window.innerWidth;
      state.isInit = true;
      await Sleep(isReadyDeray);
      state.isReady = true;
    },
  },
});
