import { default as about_45usnn0qAi4q3eMeta } from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/about-us.vue?macro=true";
import { default as contact_45usy45Glld5h0Meta } from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/contact-us.vue?macro=true";
import { default as indexAfa1eVXQy4Meta } from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/index.vue?macro=true";
import { default as indexYeWvYFkr7kMeta } from "C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/index.vue?macro=true";
export default [
  {
    name: about_45usnn0qAi4q3eMeta?.name ?? "lang-about-us",
    path: about_45usnn0qAi4q3eMeta?.path ?? "/:lang()/about-us",
    meta: about_45usnn0qAi4q3eMeta || {},
    alias: about_45usnn0qAi4q3eMeta?.alias || [],
    redirect: about_45usnn0qAi4q3eMeta?.redirect || undefined,
    component: () => import("C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/about-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usy45Glld5h0Meta?.name ?? "lang-contact-us",
    path: contact_45usy45Glld5h0Meta?.path ?? "/:lang()/contact-us",
    meta: contact_45usy45Glld5h0Meta || {},
    alias: contact_45usy45Glld5h0Meta?.alias || [],
    redirect: contact_45usy45Glld5h0Meta?.redirect || undefined,
    component: () => import("C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/contact-us.vue").then(m => m.default || m)
  },
  {
    name: indexAfa1eVXQy4Meta?.name ?? "lang",
    path: indexAfa1eVXQy4Meta?.path ?? "/:lang()",
    meta: indexAfa1eVXQy4Meta || {},
    alias: indexAfa1eVXQy4Meta?.alias || [],
    redirect: indexAfa1eVXQy4Meta?.redirect || undefined,
    component: () => import("C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYeWvYFkr7kMeta?.name ?? "index",
    path: indexYeWvYFkr7kMeta?.path ?? "/",
    meta: indexYeWvYFkr7kMeta || {},
    alias: indexYeWvYFkr7kMeta?.alias || [],
    redirect: indexYeWvYFkr7kMeta?.redirect || undefined,
    component: () => import("C:/Project/nuxt-apps/GIT  HareSystems/hare-systems-web-v1/pages/index.vue").then(m => m.default || m)
  }
]