import { defineStore } from 'pinia';
import { FlatObj } from '~/lib/lib-com';
import { LangComJa } from '@/locales/lang-com-ja';
import { LangComEn } from '@/locales/lang-com-en';

export type Lang = 'ja' | 'en';
interface State {
  state: {
    isInit: boolean;
    lang: Lang;
    message: {
      [key: string]: string;
    };
    locales: {
      en: any;
      ja: any;
    };
  };
}

export const useLangStore = defineStore({
  id: 'LangStore',
  state: (): State => {
    const route = useRoute();
    return {
      state: {
        isInit: false,
        lang: String(route.params.lang) as Lang,
        message: {},
        locales: {
          en: {
            com: LangComEn,
          },
          ja: {
            com: LangComJa,
          },
        },
      },
    };
  },
  actions: {
    init(lang: Lang) {
      this.state.message = FlatObj(this.state.locales[lang as Lang]);
      this.state.lang = lang as Lang;
    },
    setMessage(en: any, ja: any) {
      this.state.locales = { en, ja };
      this.state.message = FlatObj(this.state.locales[this.state.lang]);
    },
    t(key: string) {
      return computed(() => {
        if (!(key in this.state.message)) {
          console.error('Lang設定がありません', key);
          return key;
        }
        return this.state.message[key];
      }).value;
    },
    setLang(lang: string) {
      if (lang !== 'en' && lang !== 'ja') {
        lang = 'ja';
      }
      this.state.message = FlatObj(this.state.locales[lang as Lang]);
      this.state.lang = lang as Lang;
      const router = useRouter();
      const route = useRoute();
      router.replace(route.path.replace(/^\/[a-z]*/, '/' + this.state.lang));
    },
    toggleLang() {
      if (this.state.lang === 'ja') {
        this.setLang('en');
      } else {
        this.setLang('ja');
      }
    },
  },
});
